/* Header */

header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
  background-color: #2c0c49;
  box-shadow: 0px 3px 15px #59139821;
}

header .container {
  height: 90px;
}

header .container,
header .menu {
  display: -webkit-box;
  display: -moz-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  flex-grow: 1;
  justify-content: space-evenly;
}

header .logo {
  font-size: 30px;
  font-weight: var(--bold);
  color: var(--primarycolor);
  text-shadow: var(--shadow);
}

header .logo img {
  max-height: 80px;
}

.logo img {
  max-width: 190px;
}

header .menu {
  display: -webkit-box;
  display: -moz-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  column-gap: 10%;
  justify-content: center;
}

header .menu li > a {
  padding: 7px 1.5rem;
  border-radius: 45px;
  color: var(--white);
  display: -webkit-box;
  display: -moz-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

header .menu li > a {
  padding: 7px 1.5rem;
  border-radius: 45px;
  color: var(--white);
  display: -webkit-box;
  display: -moz-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

header .menu li > a.active,
header .menu li > a:hover {
  background-color: var(--primarycolor);
  color: #2c0c49;
}

header .menu li > a img {
  filter: brightness(0) invert(1);
  margin-right: 10px;
  max-height: 21px;
}

header .menu li > a.active img,
header .menu li > a:hover img {
  filter: none;
}

header .logout {
  display: -webkit-box;
  display: -moz-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

header .logout a {
  margin-left: 10px;
}

/* Home */

.wrapper {
  flex-grow: 1;
}

.top-banner {
  position: relative;
  text-align: center;
  padding: 4rem 0 13rem;
  z-index: -2;
}
.top-bg,
.ellipse-bg::before {
  background-image: url(../svg/left-dot.svg), url(../svg/middle-dot.svg),
    url(../svg/right-dot.svg);
  background-repeat: no-repeat;
  background-position: bottom left -45px, top 20% left 45%, top right;
  background-attachment: fixed, scroll, fixed;
  background-size: 20%, 10%, 20%;
  mix-blend-mode: overlay;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.1;
}

.ellipse-bg::before {
  content: "";
  background-attachment: scroll, scroll, fixed;
  height: 50%;
  z-index: -3;
}
.ellipse-bg::after {
  content: "";
  background-color: #591398;
  background-image: url(../img/side-dot.png);
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 15%;
  background-attachment: fixed;
  clip-path: ellipse(100% 100% at 50% 100%);
  height: 60%;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: -2;
}

.circle-animation {
  position: relative;
  display: inline-block;
  width: 90%;
  margin: 0 auto;
}

.circle-animation img:not(:last-child) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 84%;
  max-width: 84%;
}

.circle-animation .second {
  z-index: -1;
}

.circle-animation .third {
  -webkit-animation: spin 15s linear infinite;
  -moz-animation: spin 15s linear infinite;
  animation: spin 15s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* Games List */

.games-sec {
  position: relative;
  padding: 5rem 0 3rem;
  background-color: #591398;
  clip-path: ellipse(100% 100% at 50% 100%);
  margin-top: -9rem;
  z-index: 0;
}

.games-sec::after {
  content: "";
  background-image: url(../svg/dots.svg);
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: contain;
  background-attachment: fixed;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0.5;
  mix-blend-mode: screen;
  z-index: -1;
}

.games-sec .col-md-4:nth-of-type(3n + 2) {
  padding: 1.5rem 0;
}

.game-pic {
  /* border: solid 8px var(--primarycolor);    
    border-radius: 45%;
    overflow: hidden; */
  display: inline-block;
  position: relative;
  margin: 0 auto 1rem;
  width: 60%;
}

.game-pic img {
  animation: zoom-in-zoom-out 3s ease infinite;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.05, 1.05);
  }
  100% {
    transform: scale(1, 1);
  }
}

/* Modal */

.modal .btn-close {
  position: absolute;
  top: 100%;
  left: 50%;
  font-size: 1.5rem;
  transform: translateX(-50%);
  filter: invert(1) grayscale(100%) brightness(200%);
  margin-top: 0.5rem;
  opacity: 1;
}

.modal-content {
  overflow: visible;
  border-radius: var(--radius);
  color: var(--secondarycolor);
}

.modal-dialog {
  margin: 1rem auto 2.5rem;
}

.modal-backdrop.show {
  opacity: 0.8;
}

.table > :not(caption) > * > * {
  padding: 1rem 0;
  font-weight: var(--bold);
}

.table tr td:last-child,
.table tr th:last-child {
  text-align: right;
}

tbody tr:not(:last-child) td {
  border-bottom: solid 1px #2c0e4c;
}

.bg-box {
  background-color: #41126f;
  padding: 1rem;
  width: 100%;
  border-radius: var(--radius);
  color: var(--white);
}

.bg-box h4 {
  grid-column-gap: 1rem;
  display: -webkit-box;
  display: -moz-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
}

.rules {
  margin-top: 3rem;
}

.rules ul {
  max-height: calc(100vh - 19.3rem);
  overflow-y: auto;
}

.rules ul li {
  font-size: 16px;
  margin-bottom: 1rem;
  color: #d8d8d8;
}

.qr-code {
  background-color: white;
  display: inline-block;
  border-radius: 20px;
  width: 200px;
  height: 200px;
  overflow: hidden;
  padding: 0.8rem;
}

/* Login */
.mouse-move,
.vectors-move {
  position: fixed;
  overflow: hidden;
  height: 100%;
  width: 100%;
  z-index: -1;
}
.mouse-move span {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background-image: url(../img/bg-pattern.png);
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
}
.vectors-move span {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background-image: url(../svg/vectors.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
}

.login .wrapper {
  position: relative;
  padding: 2rem 0.5rem;
  z-index: 1;
  display: -webkit-box;
  display: -moz-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.form-check {
  max-width: 400px;
  margin: 0 auto;
}

/* Footer */

footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  font-size: 14px;
  padding: 1rem 0;
  background-color: #2c0c49;
}
