/* Responsive CSS */

@media screen and (max-width:1199px) {
    body {
        font-size: 16px;
    }
    h5 {
        font-size: 18px;
    }
    header .logo img {
        max-height: 70px;
    }
    header .menu li>a {
        padding: 6px 1rem 7px;
    }
    header .menu li>a img {
        margin-right: 8px;
        max-height: 16px;
    }
    header .logout img {
        width: 35px;
    }
    .form-control, .form-select {
        font-size: 4rem;
    }    
}

@media screen and (max-width:991px) {
    .rules ul {
        max-height: 100%;
        overflow-y: unset;
    }
    .form-control, .form-select {
        font-size: 3rem;
    }
    .form-check {
        max-width: 330px;
    }
    /* .form-box {
        text-align: center;
        max-width: 500px;
        margin: 0 auto;
    }    
    .form-box form {
        background: #2C0E4C 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 6px #5ac2fc6f;
        border-radius: var(--radius);
        padding: 3rem;
        font-size: 16px;
    } */
}

@media screen and (max-width:767px) {
    header .container {
        height: 60px;
        justify-content: space-between;
    }
    header .logo img {
        max-height: 45px;
    }
    header .logout {
        font-size: var(--body);
    }
    header .menu {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background: #2C0C49 0% 0% no-repeat padding-box;
        box-shadow: 0px -3px 10px #FDCD2F3B;
        border-radius: 20px 20px 0px 0px;
        display: grid;
        grid-template-columns: repeat(auto-fill, calc(100%/3 - .5rem));
        padding: .6rem 0;
        z-index: 9;
    }
    header .menu li>a {
        line-height: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    /* header .menu li>a.active, header .menu li>a:hover {
        background-color: var(--white);
        color: #2C0C49;       
    } */
    /* header .menu li>a img {
        margin: 0 0 5px;
        max-height: 18px;
    }
    .wrapper {
        padding: 60px 0 1rem;
    }
    .login .wrapper {
        padding: 2rem 0;
    }
    .top-banner {
        padding: 2rem 0 11rem;
    }
    .games-sec {
        clip-path: ellipse(200% 100% at 50% 100%);
    }
    footer {
        padding-bottom: 110px;
    }
     */
}    

@media screen and (max-width:575px) {
    /* header .container {
        height: 50px;
    }
    header .menu li>a {
        font-size: 14px;
    }
    .wrapper {
        padding: 50px 0 1rem;
    }
    .modal-dialog {
        max-width: 90%;
    }
    .rules ul li {
        font-size: 13px;
    }
    .games-sec {
        clip-path: ellipse(300% 100% at 50% 100%);
    }  
    footer {
        font-size: 12px;
    } */
    /* .form-box form {
        padding: 2.5rem .5rem;
        font-size: 15px;
    } */
}

@media screen  and (max-width:480px) {
    .confirmation-action .btn {
        font-size: 14px;
        line-height: 1.2;
        padding: 1.2em 0;
    }
}
/* @media screen and (max-width:479px) {
    header .logout {
        font-size: 14px;
    }
    header .logout img {
        width: 28px;
    }
} */