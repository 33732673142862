*::-webkit-media-controls-panel {
	display: none !important;
	-webkit-appearance: none;
}

/* Old shadow dom for play button */
*::-webkit-media-controls-play-button {
	display: none !important;
	-webkit-appearance: none;
}


:root {
	--dc-white: #FFFFFF;
	--dc-dark: #333;
	--dc-primary: #3f2e88;
	--blue: #3f2e88;
	--dc-secondary: #333;
	--black: #333;
	--dc-font: 'Montserrat';
	--dc-transition: all .3s ease;
	--dc-fs-1: 3.646vw;
	--dc-fs-2: 2.604vw;
	--dc-fs-3: 1.875vw;
	--dc-fs-4: 1.458vw;
	--dc-fs-5: 1.250vw;
	--dc-body-fs: 20px;
	--dc-body-fs-md: 16px;
	--dc-body-fs-sm: 14px;
}

html,
body,
div,
span,
applet,
object,
iframe,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}

.form-group {
	font-size: 14px;
}

.select2-results {
	display: block;
	font-size: 14px;
}

*,
::after,
::before {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

img,
svg,
video {
	max-width: 100%;
	vertical-align: middle;
}

img,
svg,
video {
	max-width: 100%;
	height: auto;
	vertical-align: middle;
}

iframe {
	border: none;
	max-width: 100%;
	max-height: 100%;
}

:focus {
	outline: 0;
}

a {
	transition: var(--dc-transition);
	text-decoration: none;
	color: currentColor;
}

a:hover {
	/*    color: var(--dc-primary);*/
	text-decoration: none;
}

svg path {
	transition: var(--dc-transition);
}

svg.text-white path {
	fill: var(--dc-white);
}

ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

.h1,
h1,
.h2,
h2,
.h3,
h3,
.h4,
h4,
.h5,
h5,
.h6,
h6 {
	font-family: var(--dc-font);
	font-weight: 700;
	margin: 0;
}


.h1,
h1 {
	font-size: var(--dc-fs-1);
}

h1.h2 {
	font-size: var(--dc-fs-2);
}

.h2,
h2 {
	font-size: var(--dc-fs-2);
}

.h3,
h3 {
	font-size: var(--dc-fs-3);
}

.h4,
h4 {
	font-size: var(--dc-fs-4);
}

.h5,
h5 {
	font-size: var(--dc-fs-5);
}

.h6,
h6 {
	font-size: var(--dc-body-fs);
}

p {
	line-height: 2rem;
}


.text-primary,
.text-blue {
	color: var(--dc-primary) !important;
}

.text-white {
	color: var(--dc-white);
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

.border-0 {
	border: 0 !important;
}

.font-md {
	font-size: var(--dc-body-fs-md);
}

.font-sm {
	font-size: var(--dc-body-fs-sm);
}

.font-normal {
	font-weight: normal;
}

.md {
	font-weight: 600;
}

ul.grad-list li {
	position: relative;
	padding-left: 2.2rem;
	margin-bottom: 0.9rem;
	line-height: 2rem;
}

ul.grad-list li::before {
	content: "";
	position: absolute;
	left: 0;
	top: 6px;
	width: 1.2rem;
	height: 1.2rem;
	border-radius: 50%;
	background: rgb(241, 0, 215);
	background: radial-gradient(circle, rgba(241, 0, 215, 1) 0%, rgba(255, 31, 47, 1) 12%, rgba(242, 83, 73, 1) 24%, rgba(255, 169, 63, 1) 36%, rgba(179, 214, 118, 1) 48%, rgba(0, 0, 255, 1) 65%, rgba(0, 0, 255, 1) 100%);
}

.grad-circle {
	display: inline-block;
	width: 1.2rem;
	height: 1.2rem;
	border-radius: 50%;
	background: rgb(241, 0, 215);
	background: radial-gradient(circle, rgba(241, 0, 215, 1) 0%, rgba(255, 31, 47, 1) 12%, rgba(242, 83, 73, 1) 24%, rgba(255, 169, 63, 1) 36%, rgba(179, 214, 118, 1) 48%, rgba(0, 0, 255, 1) 65%, rgba(0, 0, 255, 1) 100%);
}

.btn {
	font-size: 18px;
	padding: 16px 32px;
	border-radius: 10px;
	background: #000;
	color: var(--dc-white);
	display: inline-block;
	border: 1px solid #000;
	font-weight: 500;
}

label.error {
	color: #fff;
	font-size: 12px;
}

.btn.btn-white {
	background: #fff;
	color: #000;
	border-color: #fff;
}

.btn.btn-line {
	background: transparent;
	color: #000;
}

.btn:hover {
	background: var(--dc-primary);
	color: var(--dc-white) !important;
	border-color: var(--dc-primary);
}

.btn.btn-header {
	padding: 18px 42px;
	text-transform: uppercase;
}

body {
	font-family: var(--dc-font);
	font-size: var(--dc-body-fs);
	color: var(--dc-secondary);
	font-weight: 400;
	background-color: #fff5f4;
	overflow-x: hidden;
}

.container {
	max-width: 75vw;
	margin: 0 auto;
}


button,
input,
textarea,
select {
	font-family: 'Montserrat', sans-serif;
}

button:focus,
input:focus,
textarea:focus,
select:focus {
	outline: none;
}


/*Arrow btn css*/

.lg-btn-arrow {
	font-size: 22px;
	color: var(--dc-secondary);
	display: flex;
	font-weight: 500;
}

.sm-btn-arrow {
	font-size: 18px;
	color: var(--dc-secondary);
	display: flex;
	font-weight: 500;
}

.lg-btn-arrow.white-btn,
.sm-btn-arrow.white-btn {
	color: var(--dc-white);
}

.lg-btn-arrow.blue-btn,
.sm-btn-arrow.blue-btn {
	color: var(--dc-primary);
}


.arrow-link.white-arrow::before {
	border-top: 2px solid var(--dc-white);
	border-left: 2px solid var(--dc-white);
}

.arrow-link.white-arrow::after {
	background: var(--dc-white);
}

.arrow-link.blue-arrow::before {
	border-top: 2px solid var(--dc-primary);
	border-left: 2px solid var(--dc-primary);
}

.arrow-link.blue-arrow::after {
	background: var(--dc-primary);
}

.arrow-link.arrow-sm::before {
	border-top: 1px solid var(--black);
	border-left: 1px solid var(--black);
	width: 8px;
	height: 8px;
	left: 16px;
}

.arrow-link.arrow-sm::after {
	width: 16px;
	height: 2px;
}


/* Flex CSS */
.d-flex {
	display: flex;
}

.flex-wrap {
	flex-wrap: wrap !important;
}

.justify-content-start {
	justify-content: flex-start !important;
}

.justify-content-end {
	justify-content: flex-end !important;
}

.justify-content-center {
	justify-content: center !important;
}

.justify-content-between {
	justify-content: space-between !important;
}

.justify-content-around {
	justify-content: space-around !important;
}

.justify-content-evenly {
	justify-content: space-evenly !important;
}

.align-items-start {
	align-items: flex-start !important;
}

.align-items-end {
	align-items: flex-end !important;
}

.align-items-center {
	align-items: center !important;
}

.align-self-center {
	align-self: center !important;
}

.align-items-baseline {
	align-items: baseline !important;
}

.align-items-stretch {
	align-items: stretch !important;
}

.flex-shrink-0 {
	flex-shrink: 0 !important;
}

.flex-grow-1 {
	flex-grow: 1 !important;
}

.ms-3 {
	margin-left: 1rem !important;
}


/* checkbox */
.check-label {
	display: block;
	position: relative;
	padding-left: 28px;
	cursor: pointer;
	font-size: 10px;
	color: #fff;
	font-weight: 500;
	padding-top: 2px;
}

.check-label input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 16px;
	width: 16px;
	background-color: #fff;
	border-radius: 2px;
}

.check-label:hover input~.checkmark {
	background-color: #ccc;
}

.check-label input:checked~.checkmark {
	background-color: #2196F3;
}

.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

.check-label input:checked~.checkmark:after {
	display: block;
}

.check-label .checkmark::after {
	left: 6px;
	top: 2px;
	width: 5px;
	height: 10px;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}


.mobileShow {
	display: none;
}

.desktopshow {
	display: block;
}

.pagination {
	margin-bottom: 20px;
}

.pagination li {
	list-style: none;
	display: inline-block;
	font-size: 16px;
	padding: 0 2px;
}

.pagination li a {
	color: var(--dc-primary);
	font-weight: 600;
}

.pagination li.rgt-icn {
	font-size: 12px;
}


/*Common css start*/

.row {
	display: flex;
	align-items: center;
	margin: 0rem -1rem;
	flex-wrap: wrap;
}

.link-more {
	margin-top: 1.5rem;
	font-weight: 600;
	display: inline-flex;
	align-items: center;
    gap:10px;
}


.arrow-link {
    position:relative;
    display:inline-block;
    width:20px;
    
}

.arrow-link::before {
	border-top: 2px solid var(--dc-dark);
	border-left: 2px solid var(--dc-dark);
    transform: rotate(135deg) translateY(-50%);
	content: '';
	position: absolute;
	width:10px;
	height:10px;
    top:50%;
    left: 100%;
    transform-origin: 0px 1px;
    margin-top: -5px;
    margin-left: -3px;
	
}

.arrow-link::after {
	content: '';
	background: var(--dc-dark);
	width:100%;
	height: 2px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);  

}

 
.link-more-blue .arrow-link::before {
	border-top-color: var(--blue);
	border-left-color: var(--blue);
}

.link-more-blue .arrow-link::after {
	background: var(--blue);
}


.control-button {
	display: flex;
	gap: 1.5rem;
}

.control-button .Prevbutton,
.control-button .nextbutton {
	width: 3.25rem;
	height: 3.25rem;
	border-radius: 100%;
	cursor: pointer;
	border: 2px solid #3f2e88;
	background-color: #fff;
	position: relative;
    display: flex;
    align-items:center;
    justify-content:center;
}



.control-button .arrow-link:before {
	border-top: 2px solid #3f2e88;
	border-left: 2px solid #3f2e88;
}

.control-button .arrow-link:after {
	background: #3f2e88;
}

.control-button .Prevbutton .arrow-link:before {
	left: inherit;
	transform-origin:10px -7px;
	transform: rotate(312deg);
	right: 100%;
}


.control-button .Prevbutton.disabled-Prev,
.control-button .nextbutton.disabled-Next {
	background-color: #eee;
	color: grey;
	border: 1px solid grey;
	cursor: not-allowed;
	pointer-events: none;
	opacity: 0.6;
}


.slick-prev,
.slick-next {
	width: 3.25rem;
	height: 3.25rem;
	border-radius: 100%;
	background-color: #fff;
	border: 2px solid #3f2e88;
	transform: inherit;
	z-index: 9;
}

.slick-prev,
.slick-next {
	display: flex;
	align-items: center;
}

.slick-prev:before,
.slick-next:before {
	border-top: 2px solid #3f2e88;
	border-left: 2px solid #3f2e88;
	transform: rotate(135deg) translate(-50%, -50%);
	content: '';
	position: absolute;
	width: 0.5rem;
	height: 0.5rem;
	left: 1.3rem;
	position: absolute;
	transform-origin: 2px 1px;
	left: 50%;
	top: 50%;
}

.slick-prev:before {
	transform: rotate(316deg);
	left: initial;
	right: 50%;
	transform-origin: 0px 0px;
}

.slick-prev:after,
.slick-next:after {
	content: '';
	background: #3f2e88; 
	width: 1rem;
	height: 2px;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
	opacity: 1;
}




.slick-prev.slick-disabled,
.slick-next.slick-disabled {
	opacity: 0.3;
	cursor: auto;
}


.slick-next.disabled-Next {
	background-color: #eee;
	color: grey;
	border: 1px solid grey;
	cursor: not-allowed;
	pointer-events: none;
	opacity: 0.6;
}


.slick-dots li {
	width: auto;
	height: auto;
	margin: 0;
}

.slick-dots li button {
	padding: 0;
	width: auto;
	height: auto;
}

.slick-dots li button:before {
	border-radius: 100%;
	border: 2px solid #3f2e88;
	font-size: 0;
	opacity: 1;
	position: inherit;
	display: inline-block;
	margin: 0 2px;
	transition: all ease .4s;
	height: 1rem;
	width: 1rem;
}

.slick-dots li.slick-active button:before {
	width: 2rem;
	border-radius: 2rem;
	border-color: transparent;
	background: linear-gradient(to right, #f407ae 10%, #f9353a 10%, #f66d46 20%, #ffa640 40%, #b4d575 50%, #0889c6 80%, #0009fb 100%);
}

.slick-prev:hover, 
.slick-prev:focus, 
.slick-next:hover, 
.slick-next:focus{ background-color:#fff;}


.brand-sec h2 {
	text-align: center;
	color: #3f2e88;
	margin-bottom: 2rem;
}

.full-click {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 9;
}

.link-more-white {
	color: var(--dc-white);
}

.link-more-white .arrow-link::before {
	border-top-color: var(--dc-white);
	border-left-color: var(--dc-white);
}

.link-more-white .arrow-link::after {
	background: var(--dc-white);
}

.circlegradientBg {
	background: rgb(247, 0, 208);
	background: radial-gradient(circle, rgba(247, 0, 208, 1) 0%, rgba(255, 31, 47, 1) 15%, rgba(242, 83, 73, 1) 25%, rgba(255, 169, 63, 1) 35%, rgba(172, 241, 183, 1) 45%, rgba(95, 233, 255, 1) 55%, rgba(0, 0, 255, 1) 75%);
}
 
.link-more-blue {
	color: var(--blue);
}


.fix-body {
	overflow: hidden;
	padding-right: 8px;
}


.mobileDeviceShow {
	display: none;
}

/*Common css  end */


.top-strip {
	background: var(--dc-primary);
	color: #fff;
	padding: 5px 20px;
	width: 100%;
    display:none;
}

.closeStrip {
	flex-shrink: 0;
	font-size: 25px;
	width: 50px;
	text-align: center;
    cursor:pointer;
}

.cntStrip {
	flex-grow: 1;
	font-size: 16px;
}

/*
#turnstile-for-events .top-strip{
    display:flex;
}

#turnstile-for-events .navigation.with-strip .drop-down {
	top:168px;
}

#turnstile-for-events .navigation.fixed-top.with-strip .drop-down {
	top:138px;
}
*/
  


/**404 page ***/


#comingsoon {
	text-align: center;
	padding: 80px 0px;
	max-width: 760px;
	margin: 0 auto;
}

#comingsoon .virtual-event-page {
	padding-bottom: 20px;
}


/**404 page ***/


/*Bootstrap Icons*/


.bi::before,
[class^="bi-"]::before,
[class*=" bi-"]::before {
	display: inline-block;
	font-family: bootstrap-icons !important;
	font-style: normal;
	font-weight: normal !important;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	vertical-align: -.125em;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.bi-envelope::before {
	content: "\f32f";
}

.bi-telephone::before {
	content: "\f5c1";
}

.bi-whatsapp::before {
	content: "\f618";
}


.bi-arrow-down::before {
	content: "\f128";
}

.bi-arrow-left::before {
	content: "\f12f";
}

.bi-arrow-right::before {
	content: "\f138";
}

.bi-arrow-up::before {
	content: "\f148";
}

.bi-chevron-down::before {
	content: "\f282";
}

.bi-chevron-expand::before {
	content: "\f283";
}

.bi-chevron-left::before {
	content: "\f284";
}

.bi-chevron-right::before {
	content: "\f285";
}

.bi-chevron-up::before {
	content: "\f286";
}


.bi-x-circle-fill::before {
	content: "\f622";
}

.bi-x-circle::before {
	content: "\f623";
}

/*End Bootstrap Icons*/


/*image dimension start*/

.dimension-img {
	position: relative;
	margin: 0;
	padding: 0;

}


.dimension-img:before {
	content: "";
	display: block;
	padding-top: 100%;
}


.dimension-img img {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	top: 0;
	margin: auto;
	width: auto;
	-webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: 0.3s ease-in;
	transition: 0.3s ease-in;
	display: inline-block;
}

/*image dimension end*/



/*brand Section Start*/

.track {
	position: absolute;
	white-space: nowrap;
	will-change: transform;
	animation: marquee 30s linear infinite;
    -webkit-animation: marquee 30s linear infinite;
    -moz-animation: marquee 30s linear infinite;
  }
  
  @keyframes marquee {
	from { transform: translateX(0%); }
	to { transform: translateX(-50%); }
  }

@-webkit-keyframes marquee {
   from { -webkit-transform: translateX(0%); }
   to { -webkit-transform: translateX(-50%); }
}

@-moz-keyframes marquee {
   from { -moz-transform: translateX(0%); }
   to { -moz-transform: translateX(-50%); }
}




.brand-track.track img {
	max-width: 3400px;
}

.brand-inner {
	height: 80px; 
    width:100%;
    overflow:hidden;
} 

 
.brand-sec .shaper{
  background:url(https://cdn5.godcstatic.com/images/Client-logo.png) repeat 0 0 / cover;    
/*  background:url(../images/Client-logo.png) repeat 0 0 / cover;*/
  width: 100%; 
  margin: 0;
  text-align: center;  
  height:80px;
  padding-top:80px;
  box-sizing: border-box;
   animation: slide 30s linear infinite;    
  -webkit-animation: slide 30s linear infinite;
  -moz-animation: slide 30s linear infinite;
} 
 
@keyframes slide {
    from { background-position: 0 0; }
    to { background-position:-3422px 0px; }
}


@-webkit-keyframes slide {
    from { background-position: 0 0; }
    to { background-position:-3422px 0px; }
}

@-moz-keyframes slide {
    from { background-position: 0 0; }
    to { background-position:-3422px 0px; }
}

 
/*brand Section end*/



/*badges Section Start*/

.partner-logo {
	display: flex;
	justify-content: center;
	padding: 30px 0px;
	align-items: center; 
	gap: 1rem;
	max-width: 970px;
	margin: 0 auto;
}

.partner-logo li {
	position: relative;
	max-width: 7%;
	flex: 0 0 7%;
}

.partner-logo li:nth-child(3){
	max-width: 10%;
	flex: 0 0 10%;
}
  
.partner-logo li:last-child {
	margin-left: 2rem;
	max-width: 15%;
	flex: 0 0 15%;
}

.partner-logo li:last-child:before {
	height: 100%;
	width: 2px;
	background-color: var(--black);
	position: absolute;
	left: -20px;
	top: 0;
	content: '';
}

.partner-logo .dimension-img:before {
	padding-top: 115%;
}

.partner-logo .google-rating:before {
	padding-top: 32%;
}


.partner-logo li.google-rating-circel{
    display:none;
}

.partner-logo li.google-rating-circel .dimension-img:before{
    padding-top:38%;
}


/*badges Section end*/

    
.underline-text span {
    position: relative;
    display: inline-block;
    z-index: 1;
}

.underline-text span::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 15px;
    background: rgb(247,0,208);
    background: linear-gradient(90deg, rgba(247,0,208,1) 0%, rgba(255,31,47,1) 17%, rgba(242,83,73,1) 34%, rgba(255,169,63,1) 50%, rgba(172,241,183,1) 66%, rgba(95,233,255,1) 84%, rgba(0,0,255,1) 100%);
    left: 0;
    bottom: 10px;
    z-index: -1;
} 

 
.bluebg {
    background: var(--dc-primary);
}



/** faq page section **/
.faq-sec-inner {
    z-index: 99!important;
}
    


/*** safar Browser css ****/

/*@media screen and (-webkit-min-device-pixel-ratio:0) {

	html,
	body {
		overflow-x: hidden;
		-webkit-overflow-x: hidden;
		    -moz-overflow-x: hidden;
		position: relative;
	}


	::i-block-chrome,
	html,
	body {
		overflow-x: hidden;
		-webkit-overflow-x: hidden;
		    -moz-overflow-x: hidden;
		position: relative;
	}

	.brand-sec {
		overflow-x: hidden;
	}

	::i-block-chrome,
	.brand-sec {
		overflow-x: hidden;
	}

}*/

 
@media(min-width:767px) {


.slick-prev:hover:after,
.slick-next:hover:after {
	background-color: #fff;
}

.slick-prev:hover:before,
.slick-next:hover:before {
	border-top-color: #fff;
	border-left-color: #fff;
}

.slick-prev:hover,
.slick-next:hover {
	background-color: #3f2e88;
}
    

.slick-prev.slick-disabled:hover {
	background-color: #fff;
}

.slick-next.slick-disabled:hover {
	background-color: #fff;
}

.slick-prev.slick-disabled:hover:after,
.slick-next.slick-disabled:hover:after {
	background-color: #3f2e88;
}

.slick-prev.slick-disabled:hover:before,
.slick-next.slick-disabled:hover:before {
	border-top-color: #3f2e88;
	border-left-color: #3f2e88;
}    
    

    
.control-button .Prevbutton:hover,
.control-button .nextbutton:hover{
	background-color: #3f2e88;
}



.control-button .Prevbutton:hover .arrow-link:before,
.control-button .nextbutton:hover .arrow-link:before {
     border-top-color: #fff;
     border-left-color: #fff;	
}

.control-button .Prevbutton:hover .arrow-link:after,
.control-button .nextbutton:hover .arrow-link:after{
  background-color: #fff;
}    
    
    
    
    
} 




@media(min-width:1922px) {

	.h1,
	h1 {
		font-size: 70px;
	}

	h1.h2 {
		font-size: 50px;
	}

	.h2,
	h2 {
		font-size: 50px;
	}

	.h3,
	h3 {
		font-size: 36px;
	}

	.h4,
	h4 {
		font-size: 28px;
	}

	.h5,
	h5 {
		font-size: 24px;
	}

	.h6,
	h6 {
		font-size: 20px;
	}

	.container {
		max-width: 1600px;
	}
}

@media(max-width:1440px) {
	body {
		font-size: 16px;
	}

	.btn {
		font-size: 16px;
	}

	.h5,
	h5 {
		font-size: 20px;
	}

	.container {
		max-width: 82vw;
		margin: 0 auto;
	}

	p {
		line-height: 28px;
	}
}

@media(max-width:1100px) {
	body {
		font-size: 16px;
	}

	.h1,
	h1 {
		font-size: 40px;
	}

	h1.h2 {
		font-size: 30px;
	}

	.h2,
	h2 {
		font-size: 30px;
	}

	.h3,
	h3 {
		font-size: 24px;
	}

	.h4,
	h4 {
		font-size: 20px;
	}

	.h5,
	h5 {
		font-size: 18px;
	}
}

@media(max-width:991px) {

	.h1,
	h1 {
		font-size: 40px;
	}

	h1.h2 {
		font-size: 30px;
	}

	.h2,
	h2 {
		font-size: 30px;
	}

	.h3,
	h3 {
		font-size: 24px;
	}

	.h4,
	h4 {
		font-size: 20px;
	}

	.h5,
	h5 {
		font-size: 18px;
	}

	#comingsoon {
		padding: 50px 0px;
		max-width: 500px;

	}

}

@media(max-width:767px) {
    

	.h1,
	h1 {
		font-size: 30px;
	}

	h1.h2 {
		font-size: 26px;
	}

	.h2,
	h2 {
		font-size: 26px;
	}

	.h3,
	h3 {
		font-size: 20px;
	}

	p {
		line-height: 24px;
	}


	.pagination li {
		font-size: 14px;
	}

	.pagination {
		margin-bottom: 10px;
	}

	.top-strip {
		display: none;
	}



    .partner-logo {
        flex-wrap: wrap;
        padding:15px 0px;
    }

    .partner-logo li {
        position: relative;
        order: 2;
        flex: 0 0 14%;
        max-width:14%;   
    }
 
    .partner-logo li:last-child {
        margin-left: 0rem;
        order: 1;
        flex: 0 0 100%;
        max-width:100%;
        text-align: center;
    }
    
    .partner-logo li:nth-child(3){
         max-width:20%;
         flex:0 0 20%;
       }    

    .partner-logo li:last-child .g2-img1 {
        max-width: 150px;

    }

    .partner-logo .google-rating:before {
        padding-top: 15%;
    }    

    .partner-logo li:last-child:before {
        display: none;
    }
    
    
   .partner-logo li.google-rating-circel .dimension-img:before {
        padding-top:20%;
    }    

    .brand-sec .shaper{ 
        height:60px; 
        padding-top:60px;
    }
    
	/* Safari and Chrome, if Chrome rule needed */

	@media screen and (-webkit-min-device-pixel-ratio:0) {

		html,
		body {
			overflow-x: hidden;
			-webkit-overflow-x: hidden;
			-moz-overflow-x: hidden;
			position: relative;
		}


		::i-block-chrome,
		html,
		body {
			overflow-x: hidden;
			-webkit-overflow-x: hidden;
			-moz-overflow-x: hidden;
			position: relative;
		}

		.brand-sec {
			overflow-x: hidden;
		}

		::i-block-chrome,
		.brand-sec {
			overflow-x: hidden;
		}

	}
     

}  
  


