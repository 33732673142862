/* Reset CSS (Ram) */
@import url("https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap");
@font-face {
  font-family: "Rockwell";
  src: url("../fonts/Rockwell.eot");
  src: url("../fonts/Rockwell.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Rockwell.woff2") format("woff2"),
    url("../fonts/Rockwell.woff") format("woff"),
    url("../fonts/Rockwell.svg#Rockwell") format("svg");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-02DC;
}

@font-face {
  font-family: "Rockwell";
  src: url("../fonts/Rockwell Bold.eot");
  src: url("../fonts/Rockwell Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Rockwell Bold.woff2") format("woff2"),
    url("../fonts/Rockwell Bold.woff") format("woff"),
    url("../fonts/Rockwell Bold.svg#Rockwell Bold") format("svg");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-02DC;
}

:root {
  --main-font: "Figtree", sans-serif;
  --regular: 400;
  --bold: 700;
  --white: #ffffff;
  --body: 20px;
  --radius: 15px;
  --primarycolor: #fec02d;
  --secondarycolor: #333333;
  --shadow: 0 0 6px #0000004f;
  --transition: all 0.3s ease;
}
/* Reset css */

html,
body,
div,
span,
applet,
object,
iframe,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

*,
::after,
::before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

img,
svg,
video,
iframe {
  max-width: 100%;
  vertical-align: middle;
}

:focus {
  outline: 0;
}

a {
  transition: var(--transition);
  text-decoration: none;
  color: var(--secondarycolor);
}

a:hover {
  color: currentColor;
  text-decoration: none;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6,
b,
strong {
  font-weight: var(--bold);
}

sup {
  top: -0.3em;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0;
}

.table > thead {
  white-space: nowrap;
}
/* Scrollbar css */

::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  border-radius: var(--radius);
  background-color: var(--white);
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: var(--white);
}

::-webkit-scrollbar-thumb {
  background-color: var(--primarycolor);
  border-radius: var(--radius);
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--primarycolor);
}

::-webkit-scrollbar-thumb:active {
  background-color: var(--primarycolor);
}

.text-dark {
  color: var(--secondarycolor) !important;
}

body {
  font-family: var(--main-font);
  font-weight: var(--regular);
  font-size: var(--body);
  color: var(--secondarycolor);
  line-height: 1.5;
  min-height: 100vh;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  /* overflow: -moz-scrollbars-none;
    -ms-overflow-style: none; */
}

/* body::-webkit-scrollbar {
    width: 0 !important;
} */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  /* -webkit-text-fill-color: var(--dark);
-webkit-box-shadow: 0 0 0px 1000px transparent inset; */
  box-shadow: inset 0 0 4px var(--white);
  -webkit-box-shadow: inset 0 0 4px var(--white);
  transition: background-color 5000s ease-in-out 0s;
}

::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.27);
  -webkit-text-fill-color: rgba(255, 255, 255, 0.27);
}

:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.27);
  -webkit-text-fill-color: rgba(255, 255, 255, 0.27);
}

::placeholder {
  color: rgba(255, 255, 255, 0.27);
  -webkit-text-fill-color: rgba(255, 255, 255, 0.27);
}

.form-control,
.form-select {
  outline: none;
  border-radius: 10px;
  color: var(--primarycolor);
  font-size: 5rem;
  background-color: transparent;
  border: none;
  text-align: center;
  text-decoration: underline;
  text-decoration-skip-ink: none;
  text-decoration-skip: none;
  -webkit-text-fill-color: var(--primarycolor);
  color: var(--white);
  caret-color: var(--primarycolor);
}

.form-control:focus {
  color: var(--primarycolor);
  background-color: transparent;
  border-color: transparent;
  outline: 0;
  box-shadow: none;
}

label.required:after {
  content: "*";
  color: red;
  margin-left: 2px;
}

.takeSelfie .form-check {
  margin-top: 34px;
}

.form-check-label {
  font-size: 12px;
  color: var(--secondarycolor);
  padding-top: 3px;
  text-align: left;
}

/* .form-check-input[type="checkbox"] {
  border-radius: 0;
} */

.form-check .form-check-input {
  min-width: 1em;
  background-color: transparent;
  border: 1px solid #4371ee;
}

.form-check-input:checked {
  background-color: #4371ee;
  border-color: #4371ee;
}

.form-check-input:focus {
  border-color: #4371ee;
  box-shadow: none;
}

.multiselect.dropdown-toggle {
  border-radius: var(--radius);
  border: 1px solid var(--white);
  background-color: var(--white);
  background-image: url(../img/chevron-down.svg);
  background-repeat: no-repeat;
  background-position: center right 10px;
  background-size: 13px;
  text-align: left;
  padding: 0.375rem 1.5rem 0.375rem 0.75rem;
}

.multiselect.dropdown-toggle::after {
  display: none;
}

.btn-primary,
.btn-primary:hover {
  width: 100%;
  font-size: 20px;
  border-radius: 10px;
  color: #2c0c49;
  font-weight: var(--bold);
  background-color: var(--primarycolor);
  border-color: var(--primarycolor);
}

.btn-circle {
  background-color: var(--primarycolor);
  border-color: var(--primarycolor);
  border-radius: 50%;
  padding: 0.25rem;
  font-weight: var(--bold);
  transition: var(--transition);
}

.btn-circle img {
  width: 45px;
}

.btn-circle:hover {
  font-weight: var(--bold);
  background-color: var(--primarycolor);
  border-color: var(--primarycolor);
  border-radius: 50%;
  padding: 0.25rem;
  transform: scale(1.1);
}

.btn-check:focus + .btn-primary,
.btn-primary:focus {
  color: #2c0c49;
  background-color: var(--primarycolor);
  border-color: var(--primarycolor);
  box-shadow: none;
}

.btn-close:focus,
.btn:focus,
button:focus {
  outline: 0;
  box-shadow: none;
}

.container {
  max-width: 1160px;
}

/* ========= NEW DESIGN CSS ============ */

.main-max-width {
  width: 100%;
  margin: 0 auto;
  /* border-left: 1px solid #ccc;
  border-right: 1px solid #ccc; */
  min-height: 100vh;
  overflow-y: auto;
  font-family: "Figtree", sans-serif;
}

.main-max-width{
  *{
    font-family: "Figtree", sans-serif;
  }
}

.takephotoSec {
  padding: 15px 0 5px;
}

.welcome-page {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 100vh;
}

.welcome-note {
  font-size: 20px;
  font-weight: 600;
  color: var(--secondarycolor);
  text-align: center;
  margin: 20px;
  line-height: 1.3;
}

.takeSelfie {
  width: 100%;
  padding: 20px;
}

.takeSelfieCercle {
  max-width: 285px;
  margin: 0 auto;
}

.projectName {
  font-size: 28px;
  text-align: center;
  font-weight: 600;
}

.takeSelfie .form-check-label a, .takeSelfie .form-check-label span {
  color: #4371ee;
}

.cursor-pointer {
  cursor: pointer;
}

.powerd_by {
  text-align: center;
  position: fixed;
  bottom: 47px;
  left: 0;
  right: 0;
}

.powerd_by p {
  font-size: 12px;
  color: #707070;
}

.capturePart {
  padding: 20px;
}

.takeSelfiePicture {
  padding: 20px 0;
}

.takeSelfiePicture .ratio {
  background-color: #dbdbdb;
  border-radius: 10px;
}

.object-fit-cover {
  object-fit: cover;
  border-radius: 10px;
}

.camera_pic {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.large_click_btn {
  background: rgb(66, 133, 244) !important;
  background: -moz-linear-gradient(
    90deg,
    rgba(66, 133, 244, 1) 20%,
    rgba(11, 221, 148, 1) 90%
  ) !important;
  background: -webkit-linear-gradient(
    90deg,
    rgba(66, 133, 244, 1) 20%,
    rgba(11, 221, 148, 1) 90%
  ) !important;
  background: linear-gradient(
    90deg,
    rgba(66, 133, 244, 1) 20%,
    rgba(11, 221, 148, 1) 90%
  ) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4285f4",endColorstr="#0bdd94",GradientType=1) !important;
  font-size: 16px!important;
  color: #ffffff!important;
  width: 100%!important;
  border: 0!important;
  line-height: 1!important;
  border-radius: 10px !important;
  margin: 15px auto !important;
  padding: 0.8em 0 !important;
}
.large_click_btn:hover {
  color: #FFF
}

.confirmation-action .large_click_btn{
  margin: 0 !important;
}

button.retake-button {
  text-decoration: none;
  border: 1px solid #333333;
  color: #333333;
  border-radius: 10px;
  background: #FFF;
}

h3.share-pic-text {
  font-size: 18px;
  font-weight: 400;
  margin: 20px 0 30px;
  line-height: 1.6;
}

.download_btn {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  z-index: 2;
}
.download_btn:hover {
  opacity: 0.9;
}

/* ========= Footer CSS ============ */
.footerTab {
  position: fixed;
  z-index: 99;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  top: auto;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.footerTab .nav-link {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8a8a8e;
  position: relative;
  font-size: 16px;
  padding: 15px 10px;
}

.footerTab .nav-link:hover,
.footerTab .nav-link.active {
  color: #000;
  position: relative;
}

.footerTab .nav-link:hover img,
.footerTab .nav-link.active > img {
  filter: brightness(0) saturate(100%) invert(0) sepia(0%) saturate(7500%)
    hue-rotate(0deg) brightness(0%) contrast(100%);
}

.footerTab .nav-link.active::after {
  content: "";
  position: absolute;
  top: -2px;
  width: 100%;
  height: 2px;
  z-index: 1;
  left: 0;
  background-color: #000;
}

.header_app {
  background: rgb(66, 133, 244);
  background: -moz-linear-gradient(
    90deg,
    rgba(66, 133, 244, 1) 20%,
    rgba(11, 221, 148, 1) 90%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(66, 133, 244, 1) 20%,
    rgba(11, 221, 148, 1) 90%
  );
  background: linear-gradient(
    90deg,
    rgba(66, 133, 244, 1) 20%,
    rgba(11, 221, 148, 1) 90%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4285f4",endColorstr="#0bdd94",GradientType=1);
  font-size: 22px;
  color: #ffffff;
  width: 100%;
  padding: 15px 15px;
  position: static;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.profile-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 15px;
  transition: width 0.3s ease, height 0.3s ease;
}

.header-text{
  margin: 25px 0 0;
  font-size: 30px;
  font-weight: 600;
}

/* Sticky Header */
.header_app.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}


.sticky .header-content {
  display: flex;
  align-items: center;
  padding: 10px 0 0 !important;
}

.sticky .profile-image {
  width: 40px;
  /* Shrink profile image */
  height: 40px;
  /* Shrink profile image */
}

.sticky .header-text{
  font-size: 26px;
  margin:0;
  /* Reduce font size */
}

.arrow_back {
  display: flex;
}

.header-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header_app .btn-scan {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  padding: 5px 12px;
  border-radius: 30px;
  margin-left: 10px;
  color: #000;
}

.header_pro_name {
  font-size: 20px;
  font-weight: 600;
  text-align: left;
}

.userDetail {
  text-align: center;
  width: 100%;
  position: relative;
  padding: 15px 0;
}

.sticky+.main-content-wrapper {
  padding-top: 180px;
  padding-bottom: 70px;
}


/* 
.userDetail::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 50%;
  background: rgb(66, 133, 244);
  background: -moz-linear-gradient(
    90deg,
    rgba(66, 133, 244, 1) 0%,
    rgba(11, 221, 148, 1) 50%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(66, 133, 244, 1) 0%,
    rgba(11, 221, 148, 1) 50%
  );
  background: linear-gradient(
    90deg,
    rgba(66, 133, 244, 1) 0%,
    rgba(11, 221, 148, 1) 50%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4285f4",endColorstr="#0bdd94",GradientType=1);
  z-index: -1;
} */

.userDetail .ratio {
  width: 100px;
}

.userDetail img {
  border-radius: 100%;
  border: 2px solid #fff;
}

.pictureArea video, .pictureArea img {
  border-radius: 10px;
}
.regSuccessIcon {
  width: 92px;
  height: 92px;
  margin: 0 auto;
  margin-bottom: 50px;
}

.regSuccessText {
  font-size: 26px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 30px;
  background: -webkit-linear-gradient(45deg, #4285f4, #0bdd94 80%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.regText {
  font-size: 20px !important;
  font-weight: 500;
  text-align: center;
  color: #333;
  line-height: 1.4;
}

.nav-item {
  position: relative;
}
.nav-item .btn-loader {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.galleryBox {
  border-radius: 10px !important;
}

.galleryPage {
  justify-content: flex-start !important;
}

@media screen and (min-width: 991px) {
  .main-max-width, .footerTab {
    max-width: 900px;
  }
}


@media screen and (max-width: 380px) {
  .welcome-page {
    padding: 20px;
  }
  .regSuccessIcon {
    width: 82px;
    height: 82px;
  }
  .regText {
    font-size: 18px;
  }
}
.btn.share-button img {
  margin-right: 10px;
}
.btn.share-button {
  padding: 1rem 3rem !important;
  font-size: 18px;
}


.welcome-page.registered{
  justify-content: unset;
}
.event-gallery-image{
  padding: 1.5px;
  margin: 0;
}
.event-gallery-image .galleryBox {
  height: auto !important;
  aspect-ratio: 1 / 1;
}

.confirmation-action{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
    
}

.large_click_btn{
  margin: 0;
}

.confirmation-action .btn{
  width: auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  margin: 0 ;
flex: 1;
}

.image-gallery{
  /* align-items: center;
    justify-content: center;
    display: flex; */
    width: 100%;
    /* min-height: calc(100vh - 64px); */
}

.image-selection-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 15px; 
}
.sticky+.main-content-wrapper .image-selection-container{
  position: fixed;
  top: 111px;
  z-index: 1000;
  background: #FFF;
  padding: 10px;
  width: 100%;
  left:0;
  right:0;
}
.event-gallery-container .image-selection-container {
  position: fixed;
  top: 50px;
  z-index: 1000;
  background: #FFF;
  padding: 10px;
  width: 100%;
  left:0;
  right:0;
}
button.btn-select-image, button.btn-select-image:hover {
  border: 1px solid #333;
  padding: 5px 15px;
  font-size: 14px;
  line-height: 1.2;
  border-radius: 60px;
  background: transparent !important;
  color: #333 !important;
}

.footerTab.bottom-drawer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
}

.bottom-drawer .left-content {
  color: #333;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.3
}

.bottom-drawer .right-content {
  display: flex;
  align-items: center;
}
.bottom-drawer .right-content button+button {
  margin-left: 10px;
}

button.bottom-drawer-button {
  background: #f2f2f2;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  padding: 0;
}

.show-selected {
  position: absolute;
  top: 5px;
  right: 5px;
  background: #FFF;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.left-content span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.left-content span img {
  margin-right: 10px;
}