.order-2 {
    -ms-flex-order: 1 !important;
    order : 1 !important;
}

.inn-container{
    max-width: 1250px;
    margin: 0 auto;
  }
  
  .mobileDeviceShow {
      display: none;
  }
  
  .gradientBg {
      background: rgb(243, 91, 73);
      background: linear-gradient(0deg, rgba(243, 91, 73, 1) 0%, rgba(248, 174, 69, 1) 50%, rgba(118, 185, 149, 1) 100%);
  }
  
  /* Header Section */
  
  .navigation {
    position: fixed;
    width: 100%;
    top: 0%;
    left: 0%;
    z-index: 99;
    transition: all 600ms ease-in-out;
    -webkit-transition: opacity 0.4s;
    background: none;
    box-shadow: none;
  }
  
  .navigation.fixed-top {
    width: 100%;
    position: fixed;
    background-color: #fff;
    top: 0%;
    -webkit-animation: slide-down 0.7s;
    animation: slide-down 0.7s;
    border-bottom: 1px solid #f1f1f1;
    z-index: 99;
    visibility: visible;
    opacity: 1;
    display: block;
  }
  
  @keyframes slide-down {
    0% {
      opacity: 1;
      -webkit-transform: translateY(-100%);
      transform: translateY(-100%);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }
  
  .navigation .navbar-main {
    display: flex;
    flex-grow: 1;
    height: 85px;
    align-items: center;
  }
  
  .navigation .navbar-main .navbar-Text {
    color: #231f20;
    font-weight: 600;
    transition: all 600ms ease;
  }
  
  .navigation .navbar-main .navbar-Text img {
    width: 200px;
    display: inline-block;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
  }
  
  .navigation .navbar-main .navbar-list {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
  }
  
  .headerBut a:hover {
    color: #fff;
  }
  
  /* Header Section 18.10.24 */
  
  
  /* Hero Banner Content Section 18.10.24 */
  
  .fest-hero-area {
      margin: 0px auto 80px
  }
  
  .fest-hero-area p {
      margin: 20px 0 30px;
  }
  
  .vid-sec {
      padding: 60px 0 0;
      margin: 0 auto;
      position: relative;
      display: flex;
      margin-bottom: -20rem;
  }
  
  .inline-btn {
      display: flex;
      gap: 15px;
      justify-content: center
  }
  
  /* Hero Banner Content Section  The End 18.10.24 */
  
  
  /* Big Problem Section The End 18.10.24 */
  
  .big-problem{
      background-color: var(--dc-primary);
      padding: 15rem 0px 0rem;
      margin-bottom: 8rem;
  }
  
  
  .bannerMainImg {
       margin: 0px auto 0;
      background-image: url(https://cdn5.godcstatic.com/images/1810/grid.png);
      background-repeat: no-repeat;
      background-size: 50% 100%;
      background-position: top center;
      padding-top: 150px;
  }
  
  
  .bannerImg {
      flex: 0 0 60%;
      max-width: 60%;
  }
  
  .bannerRightContent{
      flex: 0 0 35%;
      max-width: 35%;
      padding: 40px;
  }
  
  .bannerRightContent h2{
    padding-bottom: 20px;
  }
  
  .bannerContentImg{
      background-image: url(https://cdn5.godcstatic.com/images/1810/big-problem.svg);
      background-repeat: no-repeat;
      background-size: 100%;
  }
  
  
  /* Big Problem Section The End 18.10.24 */
  
  /* Trusted &amp; Tried Globally Section The End */
  .trustedlogo {
    overflow: hidden;
  }
  
  .brand-sec h2 {
      text-align: center;
      color: #3f2e88;
      margin-bottom: 2rem;
  }
  
  .brand-sec .shaper {
      background: url(https://cdn5.godcstatic.com/images/Client-logo.png) repeat 0 0 / cover;
      width: 100%;
      margin: 0;
      text-align: center;
      height: 80px;
      padding-top: 80px;
      box-sizing: border-box;
      animation: slide 30s linear infinite;
      -webkit-animation: slide 30s linear infinite;
      -moz-animation: slide 30s linear infinite;
  }
  
  
  @webkit-keyframes slide {
      from { background-position: 0 0; }
      to { background-position:-3422px 0px; }
  }
  
  
  @-webkit-keyframes slide {
      from { background-position: 0 0; }
      to { background-position:-3422px 0px; }
  }
  
  @-moz-keyframes slide {
      from { background-position: 0 0; }
      to { background-position:-3422px 0px; }
  }
  
  /* Trusted &amp; Tried Globally Section The End 18.10.24 */
  
  
  
  
  /* Simple Solution Section 18.10.24 */
.cls-1 {
    stroke-dasharray: 0 0 6.38 6.38;
}
.cls-1, .cls-2 {
    fill: none;
    stroke: #000;
    stroke-miterlimit: 10;
    stroke-width: 2px;
}

.cls-3 {
    stroke-width: 0px;
}
  .simple-solutionmain{
      padding: 120px 0 60px;
        background: linear-gradient(180deg, rgba(255, 245, 244, 1) 0, rgba(255, 255, 255, 1) 100%);
  }
  
  .serviceProductcommenMain {
      display: flex;
      padding: 50px 0 0px;
      margin: 0px auto;
      justify-content: space-between;
    }
  
  .serviceProductcommenLeft {
      flex: 0 0 34.7%;
      max-width: 34.7%;
      position: relative;
  }
  
  
  .serviceProductcommenRight {
    flex: 0 0 63%;
    max-width: 63%;
      display: flex;
      flex-direction: column;
      gap: 1rem;
    position: relative;
  }
  
  .serviceBox {
      max-width: 282px;
      display: flex;
      flex-direction: column;
      text-align: center;
      gap: 1rem;
  }
  
  .arrowPoint {
      position: absolute;
      right: 17px;
      top: 50%;
      transform: translateY(-50%);
      width: 100px;
  }
  
  
  .path {
      animation: 60s linear infinite dash
  }
  
  .arrow {
      -webkit-animation: .6s ease-in-out infinite alternate uparrow
  }
  
  @keyframes dash {
      to {
          stroke-dashoffset: 2000
      }
  
      from {
          stroke-dashoffset: 0
      }
  }
  
  @-webkit-keyframes uparrow {
      0 {
          -webkit-transform: translateX(0)
      }
  
      100% {
          -webkit-transform: translateX(-.2em)
      }
  }
  
  
  
  .arrowPointRight {
    position: absolute;
    top: 50%;
    width: 100px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  
  .serviceProductcommenRight .heading {
      justify-content: space-between;
      display: flex;
  }
  
  
  .serviceBox .heading {
      justify-content: space-between;
      display: flex;
  }
  
  .heading h4 {
      background: #fff;
      font-size: 20px;
      border-radius: 20px;
      padding: 5px 10px;
      height: 36px;
  }
  
  .leftIcon, .RightIcon {
      margin-top: 1rem;
  }
  
  
  /* Simple Solution Section The End 18.10.24 */
  
  
  /* How It Works Section 18.10.24 */
  
  
  .how-it-works{
  padding: 60px 0 0px;
    background: linear-gradient(0deg, rgba(255, 245, 244, 1) 0, rgba(255, 255, 255, 1) 10%);
  }
  
  .how-it-img{
      position: relative;
      display: block;
      background-color: #f00;
      overflow: hidden;
      border-radius: 25px;
      margin: 0 auto;
      border: 5px solid transparent;
      background-image: linear-gradient(#fef4f5, #fef4f5), linear-gradient(90deg, rgba(241, 0, 215, 1) 0, rgba(255, 31, 47, 1) 15%, rgba(242, 83, 73, 1) 33%, rgba(255, 169, 63, 1) 49%, rgba(179, 214, 118, 1) 63%, rgba(0, 134, 202, 1) 83%, rgba(0, 0, 255, 1) 100%);
      background-origin: border-box;
      background-clip: content-box, border-box;
      padding: 5px 5px;
      margin-top: 50px;
  }
  
  .how-it-img img {
      padding: 30px 50px;
  }
  
  /* How It Works Section The End 18.10.24 */
  
  /* CTA Section The End 18.10.24 */
  
  .upcomingEventSection {
      padding: 6rem 0px 0rem;
  }
  
  
  .upcomingBox {
      overflow: hidden;
      margin: 0px auto;
      background-color: var(--dc-primary);
      border-radius: 15px;
      padding: 5rem 3rem;
  }
  
  .upcomingLeft {
      max-width: 70%;
      flex: 0 0 70%;
  }
  
  
  .upcomingRight {
      max-width: 30%;
      flex: 0 0 30%;
      display: flex;
      justify-content: center;
      align-items: center;
  }
  
  .upcomingRight .btn {
      background: #fff !important;
      color: #333 !important;
      text-align: center;
  }
  
  /* CTA Section The End 18.10.24 */
  /* Sharing Review Section 18.10.24 */
  
  
  .sharing-review {
      padding: 120px 0;
  }
  
  .price-hd {
      margin-bottom: 50px;
  }
  
  
  .sharing-review .d-flex.justify-content-center {
         gap: 2%;
   }
  
   .pr-bx {
      flex: 0 0 48%;
      max-width: 48%;
  }
  
  .pr-hd {
  background: #fbd4da;
  text-align: center;
  padding: 20px 10px;
  border-radius: 10px;
  }
  
  .vs {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%, -0%);
      margin-top: 15px;
  }
  
  
  .pr-cnt ul li {
      background: #fff;
      margin: 1rem 0px;
      border-radius: 10px;
      padding: 0px 40px;
      height: 80px;
      display: flex;
      align-items: center;
  }
  
  .pr-cnt ul li span{
      position: relative;
  }
  
  .pr-cnt ul li span::before {
      content: "";
      position: absolute;
      left: -20px;
      top: 7px;
      width: 0.6rem;
      height: 0.6rem;
      border-radius: 50%;
      background: #333;
  }
  
  /* Sharing Review Section The End 18.10.24 */
  
  
  /* Upper Footer Section The End 18.10.24 */
  
  
  .upper-footer {
      padding: 80px 0 0px;
     border-bottom: 10px solid var(--dc-primary);
  }
  
   
  
  .QRCodeMain {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 3rem;
      margin: 2rem 0rem;
  }
  
  
  /* Upper Footer Section The End 18.10.24 */
  
  
  /** footer**/
  
  footer {
    background-color: #fff;
    position: relative;
    padding:0;
  }
  
  footer .footerMenu-box {
    padding: 25px 0;
    margin: 0px;
  }
  
  .footerlogo {
    flex: 0 0 20%;
    max-width: 20%;
  }
  
  .footerlogo a {
    display: block;
    max-width: 240px;
  }
  
  .footermenu {
    flex: 0 0 60%;
    max-width: 60%;
    display: flex;
    justify-content: center;
  }
  
  .footermenu ul {
    display: flex;
  }
  
  .footermenu ul li {
    padding-inline: 5px;
  }
  
  .footermenu ul li a {
    font-weight: 400;
    padding: 0px 13px;
    position: relative;
  }
  
  .footermenu ul li a:hover {
    color: #e32f29;
  }
  
  .footermenu ul li a:before {
    background-color: #231f20;
    width: 5px;
    height: 5px;
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    border-radius: 100%;
    margin-right: 5px;
    transform: translateY(-50%);
  }
  
  .footerSocialIcon {
    flex: 0 0 20%;
    max-width: 20%;
  }
  
  .footerSocialIcon ul {
    display: flex;
    justify-content: flex-end;
  }
  
  .footerSocialIcon li {
    padding: 0px 5px;
  }
  .footerSocialIcon li a {
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .footerSocialIcon li a:hover {
    transform: translateY(-2px) scale(1.01);
  }
  
  .footerSocialIcon li a img {
    width: 26px;
    height: 26px;
  }
  
  /** footer The end**/
  
  
  /** Responsive **/
  @media only screen and (min-width:1600px) and (max-width:1800px){
  
  .inn-container {
      max-width: 1150px;
  }
  
  .bannerRightContent {
      flex: 0 0 35%;
      max-width: 35%;
      padding: 30px 30px 0px 30px;
  }
  
  .arrowPoint {
      right: 0px;
  }
  
  
  .big-problem {
      margin-bottom: 5rem;
  }
  
  .simple-solutionmain {
      padding: 80px 0 60px;
  }
  
  }
  
  
  @media only screen and (min-width:1440px) and (max-width:1599px){
  
  .inn-container {
      max-width: 1150px;
  }
  
  .arrowPoint {
      right: 0px;
  }
  
  
  .big-problem {
      margin-bottom: 5rem;
  }
  
  .simple-solutionmain {
      padding: 80px 0 60px;
  }
  
  }
  
  
  
  @media only screen and (min-width:1366px) and (max-width:1439px){
  
  .inn-container {
      max-width: 80vw;
  }
  
  
  
  
  .big-problem {
      margin-bottom: 5rem;
  }
  
  .simple-solutionmain {
      padding: 80px 0 60px;
  }
  
  .fest-hero-area h1 img {
      width: 80px;
  }
  
  .serviceBox {
      max-width: 235px;
  }
  
  .QRImg img {
      width: 200px;
  }
  
  }
  
  
  @media only screen and (min-width:1280px) and (max-width:1365px){
  
  .inn-container {
      max-width: 80vw;
  }
  
  
  
  
  .big-problem {
      margin-bottom: 5rem;
  }
  
  .simple-solutionmain {
      padding: 80px 0 60px;
  }
  
  .fest-hero-area h1 img {
      width: 80px;
  }
  
  .serviceBox {
      max-width: 235px;
  }
  
  .QRImg img {
      width: 200px;
  }
  
  }
  
  
  @media only screen and (min-width:1140px) and (max-width:1279px){
  
  .inn-container {
      max-width: 86vw;
  }
  
  .arrowPoint {
      right: 0px;
  }
  
  
  
  .big-problem {
      margin-bottom: 5rem;
  }
  
  .simple-solutionmain {
      padding: 80px 0 60px;
  }
  
  .fest-hero-area h1 img {
      width: 70px;
  }
  
  .serviceBox {
      max-width: 235px;
  }
  
  .QRImg img {
      width: 200px;
  }
  
  .big-problem {
      margin-bottom: 4rem;
  }
  
  .simple-solutionmain {
      padding: 80px 0 60px;
  }
  
  .how-it-works {
      padding: 20px 0 0px;
  }
  
  .sharing-review {
      padding: 80px 0;
  }
  
  }
  
  
  @media only screen and (min-width:1024px) and (max-width:1139px){
  
  .inn-container {
      max-width: 86vw;
  }
  
  .bannerRightContent {
      flex: 0 0 40%;
      max-width: 40%;
      padding: 40px;
  }
  
  .arrowPoint {
  display: none;
  }
  
  .arrowPointRight{
  display: none;
  }
  
  .big-problem {
      margin-bottom: 5rem;
  }
  
  .simple-solutionmain {
      padding: 80px 0 60px;
  }
  
  .fest-hero-area h1 img {
      width: 70px;
  }
  
  .serviceBox {
      max-width: 235px;
  }
  
  .QRImg img {
      width: 200px;
  }
  
  .big-problem {
      margin-bottom: 4rem;
  }
  
  .simple-solutionmain {
      padding: 80px 0 60px;
  }
  
  .how-it-works {
      padding: 20px 0 0px;
  }
  
  .sharing-review {
      padding: 80px 0;
  }
  
  }
  
  
  
  @media only screen and (min-width:992px) and (max-width:1023px){
  
  .inn-container {
      max-width: 86vw;
  }
  
  .bannerRightContent {
      flex: 0 0 40%;
      max-width: 40%;
      padding: 40px;
  }
  
  .arrowPoint {
    display: none;
  }
  
  .arrowPointRight {
     display: none;
  }
  
  
  
  
  .big-problem {
      margin-bottom: 5rem;
  }
  
  .simple-solutionmain {
      padding: 80px 0 60px;
  }
  
  .fest-hero-area h1 img {
      width: 70px;
  }
  
  .serviceBox {
      max-width: 235px;
  }
  
  .QRImg img {
      width: 200px;
  }
  
  .big-problem {
      margin-bottom: 4rem;
  }
  
  .simple-solutionmain {
      padding: 80px 0 60px;
  }
  
  .how-it-works {
      padding: 20px 0 0px;
  }
  
  .sharing-review {
      padding: 80px 0;
  }
  
  }
  
  
  @media only screen and (min-width:768px) and (max-width:991px){
  
  .vid-sec {
      margin-bottom: -15rem;
  }
  
  
  
  .inn-container {
      max-width: 86vw;
  }
  
  .bannerRightContent {
      flex: 0 0 45%;
      max-width: 45%;
      padding: 17px;
  }
  
  
  .bannerMainImg {
      padding-top: 60px;
  }
  
  .arrowPoint {
    display: none;
  }
  
  .arrowPointRight {
     display: none;
  }
  
  .fest-hero-area h1 img {
      width: 70px;
  }
  
  .serviceBox {
      max-width: 150px;
  }
  
  .upcomingEventSection {
      padding: 3rem 0px 0rem;
  }
  
  .QRImg img {
      width: 200px;
  }
  
  .big-problem {
      margin-bottom: 4rem;
  }
  
  .simple-solutionmain {
      padding: 80px 0 60px;
  }
  
  .how-it-works {
      padding: 20px 0 0px;
  }
  
  .sharing-review {
      padding: 80px 0;
  }
  
  
  .footerlogo {
  flex: 0 0 100%;
  max-width: 100%;
  text-align: center;
  }
  
  .footerlogo a {
    display: block;
    max-width: 240px;
    margin: 0 auto;
  }
  
  .footermenu {
  flex: 0 0 100%;
  max-width: 100%;
  margin: 20px auto;
  }
  
  .footerSocialIcon {
  flex: 0 0 100%;
  max-width: 100%;
  }
  
  .footerSocialIcon ul {
    justify-content: center;
  }
  
  }
  
  
  
  @media only screen and (min-width:0px) and (max-width:767px){
  
  .container {
    max-width: 400px;
    padding-right: 12px;
    padding-left: 12px;
  }
  
  .inn-container{
    max-width: 400px;
    padding-right: 12px;
    padding-left: 12px;
  }
  
  .mobileDeviceShow {
    display: block;
  }
  
  .vid-sec {
      margin-bottom: -9rem;
  }
  
  .btn {
    padding: 12px 20px;
    border-radius: 5px;
  }
  
  
    .navigation .navbar-main .navbar-Text img {
    width: 140px;
  }
  
  
  
  
  .bannerMainImg {
      padding-top: 60px;
  }
  
  .arrowPoint {
    display: none;
  }
  
  .arrowPointRight {
     display: none;
  }
  
  .fest-hero-area h1 img {
      width: 70px;
  }
  
  .serviceBox {
      max-width: 150px;
  }
  
  .upcomingEventSection {
      padding: 3rem 0px 0rem;
  }
  
  .QRImg img {
      width: 200px;
  }
  
  .big-problem {
    margin-bottom: 4rem;
    padding: 3rem 0px 0rem;
  }
  
  
  .serviceProductcommenMain {
      display: none;
  }
  
  .bannerMainImg .d-flex {
          flex-direction: column;
          align-items: center;
          height: 100%;
  }
  
  .bannerImg {
    flex: 0 0 100%;
    max-width: 100%;
  }
  
  
  .bannerRightContent {
    flex: 0 0 90%;
    max-width: 90%;
    padding: 30px 30px 200px 30px;
  }
  
  
  .simple-solutionmain {
      padding: 80px 0 60px;
  }
  
  .how-it-works {
      padding: 20px 0 0px;
  }
  
  .sharing-review {
      padding: 80px 0;
  }
  
  .pr-bx {
  flex: 0 0 100%;
  max-width: 100%;
  }
  
  .how-it-img img {
      padding: 10px 20px;
  }
  
  
  .how-it-img {
      border: 3px solid transparent;
      padding: 2px 2px;
      margin-top: 30px;
  }
  
  
  .upcomingBox {
      padding: 1rem 1rem;
      flex-direction: column;
  }
  
  .upcomingLeft {
    max-width: 100%;
    flex: 0 0 100%;
    text-align: center;
    margin-bottom: 1rem;
  }
  
  .upcomingRight {
      max-width: 100%;
      flex: 0 0 100%;
  }
  
  
  .sharing-review .d-flex.justify-content-center {
      flex-direction: column;
  }
  
  .upper-footer {
      padding: 40px 0 0px;
  }
  
  
  .looksLeft {
  flex: 0 0 100%;
  max-width: 100%;
  }
  
  .looksright{
    flex: 0 0 100%;
    max-width: 100%;
  }
  
  .looksMain {
      flex-direction: column;
  }
  
  
  .footerlogo {
  flex: 0 0 100%;
  max-width: 100%;
  text-align: center;
  }
  
  .footerlogo a {
    display: block;
    max-width: 140px;
    margin: 0 auto;
  }
  
  .footermenu {
  flex: 0 0 100%;
  max-width: 100%;
  margin: 20px auto;
  }
  
  .footerSocialIcon {
  flex: 0 0 100%;
  max-width: 100%;
  }
  
  .footerSocialIcon ul {
    justify-content: center;
  }
  
  .footermenu ul li.mobilehide {
  display: none;
  }
  
  .copyright {
  display: block;
  width: 100%;
  text-align: center;
  padding-top: 20px;
  }
  
  .order-1 {
  -ms-flex-order: 1;
  order: 1;
  }
  
  .order-2 {
  -ms-flex-order: 2 !important;
  order: 2 !important;
  }
  
  .order-3 {
  -ms-flex-order: 3;
  order: 3;
  }
  
  .vs {
      position: relative;
      left: 0%;
      top: 0;
      transform: none;
      margin-top: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
  }
  
  
  .simpleSlider {
      margin-top: 2rem;
  }
  
  .simpleSlider .itemslider img{
    margin: 0px auto;
    display: table;
    text-align: center;
  }
  
  .feat-name {
      font-size: 16px;
      background: #f700d0;
      background: linear-gradient(90deg, rgba(247, 0, 208, 1) 0, rgba(255, 31, 47, 1) 28%, rgba(243, 91, 73, 1) 53%, rgba(248, 174, 69, 1) 76%, rgba(118, 185, 149, 1) 100%);
      display: inline-block;
      color: #fff;
      font-weight: 600;
      padding: 6px 15px;
      border-radius: 50px;
      margin-bottom: 1.2rem;
  }
  
  
  .simpleSlider .itemslider {
      margin: 0px auto;
      display: table;
      text-align: center;
  }
  
  
  }
  
  /** Responsive The end**/
  
  
  
  
  
  